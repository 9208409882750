import React from 'react';
import SuggestionBox from './SuggestionBox';

const SuggestionList = ({ suggestions, onClick }) => {

  return (
    <div className='w-full px-4'>
      {
        suggestions.map((s) => (
          <SuggestionBox onClick={onClick} text={s}/>
        ))
      }
    </div>
    // <div className="w-full">
    //   {/* Suggestion List */}
    //   <div className='flex flex-row overflow-x-auto whitespace-nowrap mb-[16px] pl-[16px]'>
    //     {
    //       suggestions_one.map((s) => (
    //         <SuggestionBox onClick={onClick} text={s}/>
    //       ))
    //     }
    //   </div>
    //   {/* Suggestion List */}
    //   <div className='flex flex-row overflow-x-auto whitespace-nowrap mb-[16px] pl-[16px]'>
    //     <SuggestionBox onClick={onClick} text="Can I take a bath while wearing the device?"/>
    //     <SuggestionBox onClick={onClick} text="What steps should I follow when replacing the sensor?"/>
    //   </div>
    //   {/* Suggestion List */}
    //   <div className='flex flex-row overflow-x-auto whitespace-nowrap pl-[16px]'>
    //     <SuggestionBox onClick={onClick} text="How can I check my glucose reading from last month?"/>
    //     <SuggestionBox onClick={onClick} text="What do I do when there is an error scanning my device?"/>
    //   </div>
    // </div>
  );
};

export default SuggestionList;
