import React from 'react';

const SuggestionBox = ({ text, onClick }) => {
  return (
    <button className='pr-[16px] pb-[16px]' onClick={() => onClick(text)}>
      <div className="bg-abbott-black rounded-full border-white border-drake-white-35 p-[10px]">
      <div className="flex flex-row items-center text-white text-start">
        <img src="stars.png" alt="Stars" className="pr-[10px]" />
        <span className="text-sm pr-[20px] font-brandon">{text.toUpperCase()}</span>
      </div>
    </div>
    </button>
  );
};

export default SuggestionBox;
