import React from 'react';

const upperFirst = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const ResultDocument = ({ document }) => {
  
  const documentName = document.metadata.source.split("/").pop().split(".")[0].split("-").map((s) => upperFirst(s)).join(" ");
  const pageNumber = document.metadata['loc.pageNumber'];

  const openLink = () => {
    const link = document.metadata.link + "#page=" + pageNumber;
    window.open(link, '_blank');
  }

  return (
    <button className="flex items-center space-x-4 rounded-lg transition duration-300 ease-in-out hover:bg-abbott-black hover:bg-opacity-10" onClick={openLink}>
      <div className="w-32 h-24 rounded-lg bg-abbott-blue flex items-center justify-center">
        <img className="w-10 h-10" src="abbott_logo_icon.png" alt="Image Description"/>
      </div>
      <div className="flex-grow text-left">
        <p className="text-lg font-brandon-bold">{documentName} <span className="text-xs font-brandon pl-1 text-abbott-blue">Page {pageNumber}</span></p>
        <p class="text-xs h-[32px] max-w-80 overflow-ellipsis overflow-hidden">{document.pageContent}</p>
      </div>
      <img className="w-7 h-7 transform -rotate-45" src="arrow.png" alt="Arrow Image"/>
    </button>
  );
};

export default ResultDocument;