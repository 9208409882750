import React, { useRef, useState } from 'react';
import SuggestionList from './SuggestionList';
import ResultDocument from './ResultDocument';
import { cn } from "./utils/cn";

import './App.css';

const apiUrl = "https://l2a5sh2ab6n4sdaqbdte7myoe40hgese.lambda-url.us-east-1.on.aws";

function App() {

  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [output, setOutput] = useState({});

  const suggestedInput = [
    "What types of alarms does the device have?",
    "How often should I replace the sensor?",
    "Can I take a bath while wearing the device?",
    "What steps should I follow when replacing the sensor?",
    "How can I check my glucose reading from last month?",
    "What do I do when there is an error scanning my device?"
  ];

  const hasSuggestion = () => {
    return output?.suggestion;
  }

  const reset = () => {
    console.log("Reset");
    inputRef.current.value = "";
    setOutput({});
  }

  const suggest = (text) => {
    inputRef.current.value = text;
    submit()
  }

  const submit = async () => {
    setLoading(true);
    console.log(inputRef.current.value);

    const text = inputRef.current.value;

    if(!text) {
      setLoading(false);
      return;
    }
    
    try {
      const suggestion = await fetch(`${apiUrl}?query=${text}`).then((res) => res.json());

      console.log(suggestion);

      setOutput({
        documents: suggestion['documents'],
        suggestion: {text: suggestion['output']}
      });
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  }

  return (
    <div className="relative h-screen">

      {/* Background */}
      <div className="absolute top-0 left-0 h-full w-full bg-white">
        {/* <img src={hasSuggestion() ? "/result_background.png" : "/main_background.png"} className="h-full w-full object-cover"></img> */}
      </div>

      {/* Header */}
      <div className="sticky z-50 top-0 left-0 bg-abbott-black h-[70px] w-full">

        {/* Header */}
        <div className="flex flex-row justify-between items-center h-full ml-6 mr-6">

          {/* Reset Button */}
          <button onClick={() => reset()}>
            <img src="/times.png"></img>
          </button>

          {/* Logo */}
          <img src="/abbott_logo.png" width="130"></img>
        </div>
      </div>

      {/* Main Body */}
      <div className={cn("absolute z-20 left-0 w-full bg-abbott-black bg-opacity-10")}>
        <div className="flex flex-col h-full" /*justify-between"*/>
          
          {/* Welcome Text */}
          {
            !output?.suggestion && (
              <p className="mt-[64px] text-abbott-black text-7xl pl-[16px] font-brandon-light">
                Hi <span className='font-brandon'>Nithin</span>,<br/>What's on your mind?
              </p>
            )
          }

          {/* Text Field */}
          <div className={cn("relative max-w-[900px] max-h-[49px] px-[17px] mr-[17px] mt-[43px]", hasSuggestion() ? "mb-[10px]" : "mb-[83px]")}>

            {/* Button */}
            <button
              type="button" 
              className="absolute z-50 right-[28px] top-[0.3rem] h-[39px] w-[39px] rounded-full bg-drake-yellow flex items-center justify-center"
              onClick={() => submit()}
            >
              {
                loading ? (
                  <svg className="spinner p-3" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                  </svg>
                ) : (
                  <img className="p-2" src="/arrow.png"></img>
                )
              }
            </button>

            {/* Input */}
            <div className="pr-[5px] h-[49px]">
              <input type="text" ref={inputRef} placeholder='Ask me anything' className="border border-1 border-abbott-black rounded-full h-full w-full pl-[25px] pr-[50px]  font-brandon"></input>
            </div>
          </div>

          {
          hasSuggestion() && 
            (
              <p className='max-w-[900px] mx-[34px] mb-[42px] font-brandon-light text-xl'>
                {output?.suggestion?.text}
              </p>
            )
          }

          {/* Footer */}
          <div className={cn("flex-1 flex flex-col w-full bg-white", hasSuggestion() ? "" : "py-[43px]")}>

            {/* Suggestion Box / Document Results */}
            {
              hasSuggestion() ? (
                <div className=''>
                  
                  {/* AI Result */}
                  <div className="flex flex-col text-abbott-black">

                    {/* Documents */}
                    <div className="mt-[30px] pb-[30px]">
                      <h3 className="pb-[24px] pl-4 text-2xl font-brandon-bold">Related Documents</h3>
                      <div className="grid md:grid-cols-2 gap-y-4 gap-x-[8rem] px-4">
                        {
                          output?.documents?.map((doc, index) => (
                            <ResultDocument document={doc} />
                          ))
                        }
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <SuggestionList suggestions={suggestedInput} onClick={suggest} />
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
